import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { MdSearch, MdChevronRight, MdArrowUpward } from 'react-icons/md';
import { Form, Input, Choice, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import {
  Container,
  DomainsList,
  ScrollTop,
  ResultsHeader,
  ExampleResults,
} from './styles';

const sizes = [
  { id: 1, title: '1' },
  { id: 2, title: '2' },
  { id: 3, title: '3' },
  { id: 4, title: '4' },
  { id: 5, title: '5' },
];

const cjid = '100003044';
const link = `https://www.anrdoezrs.net/links/${cjid}/type/dlg/https://www.namecheap.com/domains/registration/results.aspx?domain=`;

export default function Home() {
  const [socket, setSocket] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);
  const [lastLine, setLastLine] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState({
    type: 'alphabet',
    order: 'start',
    size: '1',
  });
  const [showExamples, setShowExamples] = useState(false);
  const [doneMessage, setDoneMessage] = useState('Done!');

  useEffect(() => {
    ReactGA.pageview('/');

    const socketProtocol =
      window.location.protocol === 'https:' ? 'wss:' : 'ws:';

    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_URL
        : `${process.env.REACT_APP_URL}:${process.env.REACT_APP_PORT}`;

    const socketUrl = socketProtocol + `//${apiUrl}/domains`;

    let disconnected = false;

    setSocket(() => {
      const newSocket = new ReconnectingWebSocket(socketUrl);

      newSocket.onopen = () => {
        if (disconnected) {
          toast.success('Connected!', {
            toastId: 'wsConnected',
          });

          disconnected = false;
        }
      };

      newSocket.onerror = () => {
        toast.error('Connection lost, retrying...', {
          toastId: 'wsError',
        });
        disconnected = true;
        setHasNextPage(false);
        setLoading(false);
      };

      return newSocket;
    });
  }, []);

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    scrollContainer: 'window',
  });

  function handleLoadMore(data = searchData) {
    setShowExamples(false);

    if (data.word) {
      setLoading(true);
      fetchData(data).then(array => {
        if (!array.isLastPage) {
          setLoading(false);
          setSearchData({ ...data, line: data.line + 100 });
        } else {
          setHasNextPage(false);
          setLoading(false);
        }
      });
    }
  }

  function fetchData(data) {
    return new Promise(resolve => {
      setTimeout(() => {
        // If we are on the second reload and already have
        // over 80% of results, return and tell user his word is very uncommon
        if (data.line === 100 && results.length > 80) {
          setHasNextPage(false);
          setDoneMessage(
            "The word you selected returned too many results. You won't have trouble finding a domain with it."
          );
          resolve({ isLastPage: true });
          return;
        }

        socket.send(JSON.stringify(data));

        socket.onmessage = e => {
          const resData = JSON.parse(e.data);
          if (resData.lastLine || resData.lastLine === 0) {
            setLastLine(prevState => {
              if (resData.lastLine === prevState) {
                setHasNextPage(false);
                resolve({ isLastPage: true });
              } else {
                return resData.lastLine;
              }
            });
          } else {
            setResults(prevState => {
              const newArray = [...prevState, resData];

              if (data.type === 'alphabet') {
                newArray.sort(function(a, b) {
                  var textA = a.domain.toUpperCase();
                  var textB = b.domain.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
              }

              return newArray;
            });
          }
          resolve([...results, resData]);
        };
      }, 1000);
    });
  }

  function handleSubmit(data) {
    ReactGA.event({
      category: 'Search',
      action: data.type,
      label: data.word,
    });

    data = data.size ? data : { ...data, size: 0 };
    setDoneMessage('Done!');
    setHasNextPage(true);
    setLastLine(0);
    setResults([]);
    handleLoadMore({ ...data, line: 0 });
    setSearchData({ ...data, line: 0 });
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} autoComplete="off" initialData={searchData}>
        <div>
          <div className="searchTop">
            <Input
              type="text"
              name="word"
              placeholder="Type in a word, ex: magical"
              onKeyPress={e => {
                if (!e.key.match(/^[0-9a-z ]/gi)) e.preventDefault();
              }}
              onChange={e => {
                if (e.target.value.length > 0) {
                  const word = e.target.value;
                  const split = word.split(' ');
                  const newSplit = split.map(splitWord => {
                    return (
                      splitWord.charAt(0).toUpperCase() +
                      splitWord.slice(1).toLowerCase()
                    );
                  });

                  const escapedWord = newSplit.join('');

                  if (escapedWord.trim().length > 0) {
                    setSearchData({
                      ...searchData,
                      word: escapedWord,
                    });

                    if (searchData.type) {
                      setShowExamples(true);
                    }
                  }
                } else {
                  setSearchData({ ...searchData, word: '' });
                  setShowExamples(false);
                }
              }}
            />
            <button
              type="submit"
              disabled={!(searchData.type && searchData.word)}
            >
              <MdSearch size={20} />
              <span>Search Domains</span>
            </button>
          </div>
          <div className="options">
            <div>
              <p>Type:</p>
              <Choice
                name="type"
                options={[
                  {
                    value: 'alphabet',
                    label: window.innerWidth > 767 ? 'Alphabet' : 'ABC',
                  },
                  { value: 'wordsplus', label: 'Top Words' },
                  { value: 'dictionary', label: 'Dictionary' },
                ]}
                className={showExamples ? 'showExamples' : ''}
                onClick={e => {
                  e.target.value === 'wordsplus'
                    ? setSearchData({
                        ...searchData,
                        type: e.target.value,
                        order: 'default',
                      })
                    : setSearchData({
                        ...searchData,
                        type: e.target.value,
                        order: 'start',
                        size: e.target.value === 'alphabet' ? 1 : 2,
                      });
                  if (searchData.word) {
                    setShowExamples(true);
                  }
                }}
              />
            </div>
            {searchData.type &&
              (searchData.type !== 'wordsplus' ? (
                <>
                  <div>
                    <p>Add a:</p>
                    <Choice
                      name="order"
                      options={[
                        { value: 'start', label: 'Suffix' },
                        { value: 'end', label: 'Prefix' },
                      ]}
                      onClick={e =>
                        setSearchData({ ...searchData, order: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <p>with:</p>
                    <Select
                      name="size"
                      options={
                        searchData.type === 'alphabet'
                          ? sizes.slice(0, 2)
                          : sizes.slice(1)
                      }
                      onChange={e =>
                        setSearchData({ ...searchData, size: e.target.value })
                      }
                    />
                    <p>
                      {searchData.size === '1' || searchData.size === 1
                        ? 'character'
                        : 'characters'}
                      .
                    </p>
                  </div>
                </>
              ) : (
                <div>
                  <p>Order:</p>
                  <Choice
                    name="order"
                    options={[
                      { value: 'default', label: 'Default' },
                      {
                        value: 'start',
                        label:
                          window.innerWidth > 767 ? 'Add Suffix' : 'Suffix',
                      },
                      {
                        value: 'end',
                        label:
                          window.innerWidth > 767 ? 'Add Prefix' : 'Prefix',
                      },
                    ]}
                    onClick={e =>
                      setSearchData({ ...searchData, order: e.target.value })
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      </Form>
      {showExamples && (
        <ExampleResults>
          {(() => {
            switch (searchData.type) {
              case 'alphabet':
                return searchData.order === 'start' ? (
                  <p>
                    Search for available domains using the alphabet, ex:
                    <br />
                    {searchData.word}
                    <strong>A</strong>.com, {searchData.word}
                    <strong>B</strong>.com, {searchData.word}
                    <strong>C</strong>.com...
                  </p>
                ) : (
                  <p>
                    Search for available domains using the alphabet, ex:
                    <br /> <strong>A</strong>
                    {searchData.word}.com, <strong>B</strong>
                    {searchData.word}.com, <strong>C</strong>
                    {searchData.word}
                    .com...
                  </p>
                );
              case 'wordsplus':
                switch (searchData.order) {
                  case 'default':
                    return (
                      <p>
                        Search for available domains with popular domain
                        suffixes and prefixes, ex:
                        <br /> <strong>My</strong>
                        {searchData.word}.com, {searchData.word}
                        <strong>Online</strong>.com, <strong>Web</strong>
                        {searchData.word}.com...
                      </p>
                    );
                  case 'start':
                    return (
                      <p>
                        Search for available domains with popular domain
                        suffixes, ex:
                        <br /> {searchData.word}
                        <strong>Tv</strong>.com, {searchData.word}
                        <strong>Co</strong>.com, {searchData.word}
                        <strong>Me</strong>.com...
                      </p>
                    );
                  case 'end':
                    return (
                      <p>
                        Search for available domains with popular domain
                        prefixes, ex:
                        <br /> <strong>My</strong>
                        {searchData.word}.com, <strong>Go</strong>
                        {searchData.word}.com, <strong>Mr</strong>
                        {searchData.word}.com...
                      </p>
                    );
                  default:
                    break;
                }
                break;
              case 'dictionary':
                return searchData.order === 'start' ? (
                  <p>
                    Search for available domains using a dictionary, ex:
                    <br /> {searchData.word}
                    <strong>Ace</strong>.com, {searchData.word}
                    <strong>Act</strong>.com, {searchData.word}
                    <strong>Add</strong>.com...
                  </p>
                ) : (
                  <p>
                    Search for available domains using a dictionary, ex:
                    <br /> <strong>Ace</strong>
                    {searchData.word}.com, <strong>Act</strong>
                    {searchData.word}.com, <strong>Add</strong>
                    {searchData.word}.com...
                  </p>
                );
              default:
                return null;
            }
          })()}
        </ExampleResults>
      )}

      {results.length ? (
        <ResultsHeader>
          <p>{results.length} available domains found.</p>
        </ResultsHeader>
      ) : (
        ''
      )}
      <DomainsList ref={infiniteRef}>
        {results.map(result => {
          if (result.domain) {
            return (
              <ReactGA.OutboundLink
                key={result.domain}
                eventLabel={result.domain}
                to={`${link}${result.domain}`}
                target="_blank"
              >
                <li>
                  <div>
                    <strong>{result.domain}</strong>
                  </div>
                  <div className="time-container">
                    {/* <p>{result.formattedDate}</p> */}
                    <MdChevronRight size={20} />
                  </div>
                </li>
              </ReactGA.OutboundLink>
            );
          }
          return null;
        })}
        {loading && (
          <div>
            <li>Loading...</li>
          </div>
        )}
        {hasNextPage || (
          <div>
            {results.length > 0 ? (
              <li>{doneMessage}</li>
            ) : (
              <li>
                {searchData.type
                  ? 'No results found.'
                  : `The domain ${searchData.word} is not available`}
              </li>
            )}
          </div>
        )}
      </DomainsList>
      <ScrollTop visible={results.length > 20}>
        <button onClick={() => window.scrollTo(0, 0)}>
          <MdArrowUpward size={40} />
        </button>
      </ScrollTop>
    </Container>
  );
}
