import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 960px;
  margin: 50px auto;
  width: 90%;

  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: top;
    justify-content: space-around;

    input[type='text'],
    input[type='number'] {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: #fff;
      padding: 0 15px;
      font-size: 20px;
      margin: 0 5px 0 0;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    select {
      display: inline-block;
      padding: 10px 30px 10px 20px;
      margin-right: 10px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 2px solid #444;
      border-radius: 4px;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #ddd;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABQCAYAAAC+neOMAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAA5tJREFUeJzt2s9rHGUYwPHvM9lpKjYQFENBEIzSelHwPxBMeuhNJYtxAioehKyiNw8ik148CFLqtpG9WEI6G38h1EhPQgTvnjVCD4pVPBg8eKhJ5vHS3Wzipju7O+/7TuH5nmZnZncePpmEd4eAZVmWZVmWZVmWZVmWZVmWZVnWEEmRk9I0jU6fOfPYvsgD5PkfjST5RUTU9XCuaq6tPRhFk7NM5Lf3pqd/euv8+duD3nNXqDRNo5mzZxuq+o4gD3cPKD9C/m5jaemrEub2VvPatScj5ENE5nt2/6Oqn8Qn4vder9f/Pu69x0K1Wq147/6pDYQXjjtHlZU3ll66MOLcXmu22/NRrtcRua/vCarbTERzjcXFX/sd7gvVarXi3VNTnwo8P3gEvdBIkpXiI/vvyvrGOY30usDJu52ncFMieaYfVnR0R7q1Vds9NZUVQwKQ9EqWrRSc2XtFkQAEZjXX71prnz9y9NghqHRrqzbz2+/rAgvDjVNNrGGQOgnM7tX2/od1CGrm1q0PEF4cbaxqYY2C1NOjuxO7m5du3Jjs7OhCfZRlT6jy9njjVQOr2W7Pj4EEgIg8VdvZea3zOurZWBCRQuuqAZcIitVst+dF+XocpJ66f4IOfvVUHi/hg+8UBqtkJODApAslogNXp0NexCtW+Uig8G9nu/eO+qGsCxzkB8sFEoCgXZPeO+ozhZ0yL3Tnk51iuUICEGh1trtQy0myI+jLqrpf9gVdYa1m2ZwrJJSPl5Pk287LQ+uoRpJsRsLivYC1mmVzObLpBAldf+hE7c3ePX2XA6tZtpArGyIy4WCIsb8bOkeK41fr9fqhm+XYdVNVsUIgwYDnUVXDCoUEBZ5wVgUrJBIUfBQcGuvy+sazRPpNKCQoCAXhsKqABENAgX+sqiDBkFDgD6tKSDACFLjH0jz6vkpIMCIUuMVS1X1HP4SRkGAMKHB9Z5Xd6EgwJhTcK1jjIUEJUFB1rPGRoCQoqCpWOUhQIhRUDas8JCgZCqqCVS4SOICC0FjlI4EjKAiF5QYJHEKBbyx3SOAYCnxhuUUCD1Dg/LuhcyTwBAWusPwggUcoKBvLHxJ4hoKysPwiQQAoGBfLPxIEgoJRscIgQUAoGBYrHBIEhoKiWGGRoM+/T/tuOUm+EOE5Vf3r6DFV3Ud5/8/t7VdCIkEF7qhOF69enY7jyQWBp4EY+FlEv1xOkpuhZ7Msy7Isy7Isy7IsyxrcfzNeLxtxSlaeAAAAAElFTkSuQmCC');
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;

      &::-ms-expand {
        display: none;
      }
      &:hover {
        background-color: #dfd;
      }

      &:focus {
        background-color: #bfb;
        border-color: #4c4;
        position: relative;
      }

      & option {
        font-weight: normal;
      }
    }

    > div {
      flex-direction: column;
      width: 100%;

      .searchTop {
        display: flex;
        flex-direction: row;

        input[type='text'] {
          width: 80%;
          margin: 0 20px 0 0;
        }

        button {
          border: 0;
          background: #f94d6a;
          color: #fff;
          font-weight: bold;
          padding: 5px 20px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          transition: 0.5s background;
          height: 42px;
          min-width: 170px;
          flex: 1;
          justify-content: center;

          @media (max-width: 767px) {
            min-width: initial;
            padding: 5px 10px;

            span {
              display: none;
            }
          }

          svg {
            margin-right: 5px;

            @media (max-width: 767px) {
              margin-right: 0;
            }
          }

          &:hover {
            background: ${darken(0.1, '#f94d6a')};
          }

          &:disabled,
          &[disabled] {
            background: #999;
          }
        }
      }

      .options {
        margin-top: 10px;
        align-items: center;
        display: flex;

        @media (max-width: 767px) {
          display: block;
        }

        div {
          margin-right: 20px;
          margin-bottom: 10px;
          height: 44px;
          display: flex;
          align-items: center;
          position: relative;

          span {
            display: block;
            position: absolute;
            top: calc(100% + 10px);
            color: red;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        p {
          display: inline-block;
          color: white;
          margin-right: 10px;

          @media (max-width: 767px) {
            min-width: 40px;
            font-weight: bold;
          }
        }

        input[type='radio'] {
          opacity: 0;
          position: fixed;
          width: 0;

          &:focus + label {
            border: 2px dashed #444;
          }

          &:checked + label {
            background-color: #bfb;
            border-color: #4c4;
            position: relative;
          }

          &.showExamples {
            &:checked + label {
              background-color: #bfb;
              border-color: #4c4;
              position: relative;

              @media (min-width: 768px) {
                &:after {
                  content: ' ';
                  position: absolute;
                  bottom: -23px;
                  left: 50%;
                  margin-left: -16px;
                  border-width: 15px;
                  border-style: solid;
                  border-color: transparent transparent #00000070 transparent;
                }
              }
            }
          }
        }

        label {
          display: inline-block;
          background-color: #ddd;
          padding: 10px 20px;
          font-family: sans-serif, Arial;
          font-size: 16px;
          border: 2px solid #444;
          border-radius: 4px;

          @media (max-width: 767px) {
            padding: 10px;
          }

          &:hover {
            background-color: #dfd;
          }
        }
      }
    }
  }

  ul {
    display: block;
    margin-top: 30px;
  }
`;

export const ResultsHeader = styled.div`
  display: flex;
  color: #fff;
  margin-top: 20px;
  padding: 0 10px;
  font-size: 18px;
`;

export const DomainsList = styled.ul`
  a,
  div {
    li {
      padding: 20px;
      border-radius: 4px;
      margin-bottom: 20px;
      background: rgba(0, 0, 0, 0.1);
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      strong {
        display: block;
        font-size: 20px;
        font-weight: normal;
      }

      span {
        display: block;
        margin-top: 3px;
      }

      .time-container {
        display: flex;
        p {
          margin-right: 30px;
          color: #999;
        }
      }
    }
  }
`;

export const ScrollTop = styled.div`
  button {
    opacity: 0.6;
    padding: 20px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    color: white;
    font-weight: bold;
    opacity: ${props => (props.visible ? 1 : 0)};
    transition: opacity 1s;
    background: transparent;
    border: 0;

    &:hover {
      opacity: 1;
    }
  }
  cursor: pointer;
`;

export const ExampleResults = styled.div`
  margin-top: 10px;
  padding: 10px 15px;
  background: #00000070;
  border-radius: 4px;
  align-self: flex-start;

  p {
    font-size: 18px;
    display: inline-block;
  }
`;
