import styled from 'styled-components';

export const Container = styled.div`
  max-width: 960px;
  margin: 50px auto;
  width: 90%;

  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 20px;
    max-width: 600px;
    font-size: 20px;
  }
`;
