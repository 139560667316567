import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import { Container } from './styles';

export default function About() {
  useEffect(() => {
    ReactGA.pageview('/about');
  }, []);

  return (
    <>
      <Helmet>
        <title>About NameLazy</title>
        <meta
          content="Everybody knows that all good domains are already taken... but are they really?"
          name="description"
        />
      </Helmet>
      <Container>
        <h1>About NameLazy</h1>
        <p>
          Everybody knows that all good domains are already taken...
          <br /> but are they really?
        </p>
        <p>
          Whenever I needed a name for a new project I would go to
          namecheap.com, type in the main project keyword and try all possible
          variations under the sun.
        </p>
        <p>
          One day I realised how repetitive that task was and decided to create
          a small app to help me. It worked suprisingly well.
        </p>
        <p>
          No guarantees this will work for you, but at least for me, it beats
          typing domains one-by-one any day.
        </p>
        <p>
          I know this isn't perfect, so if you find any bugs or have
          suggestions, please send me an email at andre@vitorio.net.
          <br /> I hope you find the perfect domain for your project!
        </p>
      </Container>
    </>
  );
}
